import styles from "./style.module.scss";
import { useRef } from "react";
import { useScroll, motion, useTransform } from "framer-motion";
import Magnetic from "../Magnetic/Index";
import { BiLogoFacebook } from "react-icons/bi";
import { RiTwitterXLine } from "react-icons/ri";
import { FaLinkedinIn } from "react-icons/fa";
import { RiInstagramLine } from "react-icons/ri";
import { RiYoutubeFill } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import EnquiryForm from "../Form/EnquiryForm";

const Index = ({Data}) => {
    const currentYear = new Date().getFullYear();
    const container = useRef(null);
    const { scrollYProgress } = useScroll({
        target: container,
        offset: ["start end", "end end"]
    });
   
    const combinedClassNames = `${styles.contact} transformRemove`;

  const x = useTransform(scrollYProgress, [0, 1], [0, 100]);
  const y = useTransform(scrollYProgress, [0, 1], [-500, 0]);

    const location = useLocation();
    const slug = location.pathname;
    const isSlugPage = location.pathname.startsWith('/projects/') && location.pathname.split('/').length === 4;
    const titleDivision = isSlugPage ? (
        <div className="title text-center float-start col-12">
            <span>FILL THE FORM TO DOWNLOAD</span>
        </div>
    ) : (
        <div className="title text-center float-start col-12">
            <span>Talk to us</span>
            <p className='m-0 lh-1'>For excellent real estate ownership and <br />investment opportunities</p>
        </div>
    );

    return (
        <>
            <footer className='col-12 float-start overflow-hidden' data-aos="fade-in"
                data-aos-offset="200"
                data-aos-duration="500"
                data-aos-once="true"
                data-aos-easing="ease-in-sine">
                <motion.div style={{ y }} ref={container} className={combinedClassNames}>
                    <div className="container">
                        {titleDivision}
                        <div className='col-lg-11 m-auto'>
                            <div className={styles.body}>
                                <div className='col-lg-9 m-auto footerForm'>
                                    <EnquiryForm />
                                </div>
                                <div className={styles.title}>
                                    <div className={styles.nav}>
                                        <div className="footerbox">
                                            <span className='call'></span>
                                            <p className='col-12 float-start m-0'> <a href='tel:9999980055' >+91 99999 80055</a></p>
                                        </div>
                                        <div className="footerbox">
                                            <span className='map'></span>
                                            <p className="col-12 float-start m-0">
  {slug === '/' ? (
    // Home Page address
    <>
    29th Floor, The Wing <br/> Boulevard Walk <br/> Greater Noida West
    </>
  ) : slug === '/projects/commercial' ? (
    // Commercial Page Address 
    <>
    Plot No. C-2 <br/> Sector - 4 <br/> Greater Noida West
    </>
  ) : slug.includes('/project') ? (
    // Project Detail page Address 
    Data && Data.trim() ? (
      <p dangerouslySetInnerHTML={{ __html: Data }} className="m-0" />
    ) : (
      <>
        GH-B3, Jaypee Greens Sports <br />
        City, SDZ, Dankaur, Greater <br />
        Noida, Gautam Buddha Nagar <br />
        Uttar Pradesh, 201301 test
      </>
    )
  ) : (
    // Default Address
    <>
      GH-B3, Jaypee Greens Sports <br />
      City, SDZ, Dankaur, Greater <br />
      Noida, Gautam Buddha Nagar <br />
      Uttar Pradesh, 201301 test2
    </>
  )}
</p>
                                            
                                        </div>
                                        <div className="footerbox">
                                            <span className='email'></span>
                                            <p className='col-12 float-start m-0'> <a href='mailto:info@homeandsoul.in'>info@homeandsoul.in</a></p>
                                        </div>
                                        <div className="footerbox">
                                            <div className="col-12 float">
                                                <p className='m-0'>Follow us today!</p>
                                                <ul className='d-flex p-0'>
                                                    <li><a href='https://www.facebook.com/HomeAndSoulIN' target='_blank' rel="noreferrer"><Magnetic><span><BiLogoFacebook /></span></Magnetic></a></li>
                                                    <li><a href='https://twitter.com/Home_and_Soul' target='_blank' rel="noreferrer"><Magnetic><span><RiTwitterXLine /></span></Magnetic></a></li>
                                                    <li><a href='https://www.linkedin.com/company/homeandsoul/' target='_blank' rel="noreferrer"><Magnetic><span><FaLinkedinIn /></span></Magnetic></a></li>
                                                    <li><a href='https://www.instagram.com/homeandsoulin' target='_blank' rel="noreferrer"><Magnetic><span><RiInstagramLine /></span></Magnetic></a></li>
                                                    <li><a href='https://www.youtube.com/channel/UCwqwdgrB6KKyiBHXwirCnTA' target='_blank' rel="noreferrer"><Magnetic><span><RiYoutubeFill /></span></Magnetic></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.info}>
                                    <div>
                                        <p>
                                            &copy; {currentYear} | All Right Reserved | Home & Soul | <Link to='/privacy-policy'>Privacy & Policy</Link> | <Link to='/disclaimer'> Disclaimer</Link>
                                        </p>
                                    </div>
                                    <div>
                                        <span>
                                            <Magnetic>
                                                <p><a href='https://triverseadvertising.com/' target='_blank' rel="noreferrer">site : triverse</a></p>
                                            </Magnetic>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </footer>
        </>
    );
}

export default Index;
