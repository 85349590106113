import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Lenis from "@studio-freight/lenis";
import SplitType from "split-type";
import WebContainer from '../WebContainer/Index'; // Adjust this path as needed
import styles from './style.module.css';
import QuoteIcon from '../../images/Svg/QuoteIcon';

// Register GSAP ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

const Index = ({ Data }) => {
  useEffect(() => {
    // Initialize Lenis for smooth scrolling
    const lenis = new Lenis();

    // Request animation frame for Lenis smooth scroll functionality
    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);
    const splitTypes = document.querySelectorAll('.reveal-type');

    splitTypes.forEach((char) => {
      const bg = char.dataset.bgColor;
      const fg = char.dataset.fgColor;

      const text = new SplitType(char, { types: 'words' });

      const keyword = "special"; 
      text.words.forEach((word, index) => {
        if (word.innerText.toLowerCase() === keyword.toLowerCase()) {
          word.innerHTML = `<div class="${styles.keyword}">${word.innerHTML}</div>`;
        }
      });

      gsap.fromTo(
        text.words,
        { color: bg },
        {
          color: fg,
          duration: 0.3,
          stagger: 0.02,
          scrollTrigger: {
            trigger: char,
            start: "top 80%",
            end: "top 20%",
            scrub: true,
            markers: false,
            toggleActions: "play play reverse reverse",
          },
        }
      );
    });

    return () => {
      lenis.destroy();
    };
  }, []);

  return (
    <WebContainer _parentClass={'QuoteText text-center position-relative p-100 m-0'}>
                <span className='QuoteIcon'><QuoteIcon /></span>
      <p
        className={`${styles.paragraph} reveal-type textEffect`}
        data-bg-color="#cccccc"
        data-fg-color="black"
      >
        {Data}
      </p>
    </WebContainer>
  );
};

export default Index;
